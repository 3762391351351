import "./../scss/style.scss";
import HTML_index from "./../index.html";
import HTML_portfolio from "./../portfolio.html";
import HTML_services from "./../services.html";
import HTML_contact from "./../contact.html";
import HTML_en_index from "./../en/index.html";
import HTML_en_portfolio from "./../en/portfolio.html";
import HTML_en_services from "./../en/services.html";
import HTML_en_contact from "./../en/contact.html";
import IMG_portfolio_stepicons_1 from "./../images/portfolio/1_StepIcons/1.png";
import IMG_portfolio_stepicons_2 from "./../images/portfolio/1_StepIcons/2.png";
import IMG_portfolio_stepicons_3 from "./../images/portfolio/1_StepIcons/3.png";
import IMG_portfolio_stepicons_4 from "./../images/portfolio/1_StepIcons/4.png";
import IMG_portfolio_mevaicons_1 from "./../images/portfolio/2_MevaIcons/1.png";
import IMG_portfolio_popsicle_1 from "./../images/portfolio/3_Popsicle/1.png";
import IMG_portfolio_popsicle_2 from "./../images/portfolio/3_Popsicle/2.png";
import IMG_portfolio_popsicle_3 from "./../images/portfolio/3_Popsicle/3.png";
import IMG_portfolio_popsicle_4 from "./../images/portfolio/3_Popsicle/4.png";
import IMG_portfolio_popsicle_5 from "./../images/portfolio/3_Popsicle/5.png";
import IMG_portfolio_popsicle_6 from "./../images/portfolio/3_Popsicle/6.png";
import IMG_portfolio_popsicle_7 from "./../images/portfolio/3_Popsicle/7.png";
import IMG_portfolio_tinyhouse_1 from "./../images/portfolio/4_TinyHouse/1.png";
import IMG_portfolio_tinyhouse_2 from "./../images/portfolio/4_TinyHouse/2.png";
import IMG_portfolio_tinyhouse_3 from "./../images/portfolio/4_TinyHouse/3.png";
import IMG_portfolio_tinyhouse_4 from "./../images/portfolio/4_TinyHouse/4.png";
import IMG_portfolio_tinyhouse_5 from "./../images/portfolio/4_TinyHouse/5.png";
import IMG_portfolio_tinyhouse_6 from "./../images/portfolio/4_TinyHouse/6.png";
import IMG_portfolio_tinyhouse_7 from "./../images/portfolio/4_TinyHouse/7.png";
import IMG_portfolio_tinyhouse_8 from "./../images/portfolio/4_TinyHouse/8.png";
import IMG_portfolio_tinyhouse_9 from "./../images/portfolio/4_TinyHouse/9.png";
import IMG_portfolio_tinyhouse_10 from "./../images/portfolio/4_TinyHouse/10.png";
import IMG_portfolio_tinyhouse_11 from "./../images/portfolio/4_TinyHouse/11.png";
import IMG_portfolio_tinyhouse_12 from "./../images/portfolio/4_TinyHouse/12.png";
import IMG_portfolio_kidsillustrations_1 from "./../images/portfolio/5_KidsIllustration/1.png";
import IMG_portfolio_kidsillustrations_2 from "./../images/portfolio/5_KidsIllustration/2.png";
import IMG_portfolio_kidsillustrations_3 from "./../images/portfolio/5_KidsIllustration/3.png";
import IMG_portfolio_kidsillustrations_4 from "./../images/portfolio/5_KidsIllustration/4.png";
import IMG_portfolio_kidsillustrations_5 from "./../images/portfolio/5_KidsIllustration/5.png";
import IMG_portfolio_kidsillustrations_6 from "./../images/portfolio/5_KidsIllustration/6.png";
import IMG_portfolio_kidsillustrations_7 from "./../images/portfolio/5_KidsIllustration/7.png";
import IMG_portfolio_rekrutacjebudowlane_1 from "./../images/portfolio/6_RekrutacjeBudowlane/1.png";
import IMG_portfolio_rekrutacjebudowlane_2 from "./../images/portfolio/6_RekrutacjeBudowlane/2.png";
import IMG_portfolio_rekrutacjebudowlane_3 from "./../images/portfolio/6_RekrutacjeBudowlane/3.png";
import IMG_portfolio_rekrutacjebudowlane_4 from "./../images/portfolio/6_RekrutacjeBudowlane/4.png";
import IMG_portfolio_mobileuikit_1 from "./../images/portfolio/7_MobileUIKit/1.jpg";
import IMG_portfolio_mobilefinancial_1 from "./../images/portfolio/8_MobileFinancial/1.png";
import IMG_portfolio_logospack_1 from "./../images/portfolio/9_LogosPack1/1.png";
import IMG_portfolio_logospack_2 from "./../images/portfolio/9_LogosPack1/2.png";
import IMG_portfolio_logospack_3 from "./../images/portfolio/9_LogosPack1/3.png";
import IMG_portfolio_logospack_4 from "./../images/portfolio/9_LogosPack1/4.png";
import IMG_portfolio_logospack_5 from "./../images/portfolio/9_LogosPack1/5.png";
import IMG_portfolio_logospack_6 from "./../images/portfolio/9_LogosPack1/6.png";
import IMG_portfolio_logospack_7 from "./../images/portfolio/9_LogosPack1/7.png";
import IMG_portfolio_newsletterjacro_1 from "./../images/portfolio/10_NewsletterJacro/1.png";
import IMG_portfolio_airbike_1 from "./../images/portfolio/11_airbike/1.png";
import IMG_portfolio_platinum_1 from "./../images/portfolio/12_platinum/1.png";
import IMG_portfolio_platinum_2 from "./../images/portfolio/12_platinum/2.png";
import IMG_portfolio_platinum_3 from "./../images/portfolio/12_platinum/3.png";
import IMG_portfolio_platinum_4 from "./../images/portfolio/12_platinum/4.png";
import IMG_portfolio_cvscouter_1 from "./../images/portfolio/13_cvscouter/1.png";
import IMG_portfolio_cvscouter_2 from "./../images/portfolio/13_cvscouter/2.png";
import IMG_portfolio_cvscouter_3 from "./../images/portfolio/13_cvscouter/3.png";
import IMG_portfolio_finfront_1 from "./../images/portfolio/14_finfront/1.png";
import IMG_portfolio_fash_1 from "./../images/portfolio/15_fash/1.png";
import IMG_portfolio_tenko_1 from "./../images/portfolio/16_tenko/1.png";
import IMG_portfolio_cvmaker_1 from "./../images/portfolio/17_cvmaker/1.png";
import IMG_portfolio_cvmaker_2 from "./../images/portfolio/17_cvmaker/2.png";
import IMG_portfolio_cvmaker_3 from "./../images/portfolio/17_cvmaker/3.png";
import IMG_portfolio_cvmaker_4 from "./../images/portfolio/17_cvmaker/4.png";
import IMG_portfolio_cvmaker_5 from "./../images/portfolio/17_cvmaker/5.png";
import IMG_portfolio_cvmaker_6 from "./../images/portfolio/17_cvmaker/6.png";
import IMG_portfolio_cvmaker_7 from "./../images/portfolio/17_cvmaker/7.png";
import IMG_portfolio_cvmakerdash_1 from "./../images/portfolio/18_cvmakerdash/1.png";
import IMG_portfolio_cvmakerdash_2 from "./../images/portfolio/18_cvmakerdash/2.png";
import IMG_portfolio_cvmakerdash_3 from "./../images/portfolio/18_cvmakerdash/3.png";
import IMG_portfolio_cvmakerdash_4 from "./../images/portfolio/18_cvmakerdash/4.png";
import IMG_portfolio_cvmakerdash_5 from "./../images/portfolio/18_cvmakerdash/5.png";
import IMG_portfolio_cvmakerdash_6 from "./../images/portfolio/18_cvmakerdash/6.png";
import IMG_portfolio_cvmakerdash_7 from "./../images/portfolio/18_cvmakerdash/7.png";
import IMG_portfolio_cvmakerwizard_1 from "./../images/portfolio/19_cvmakerwizard/1.png";
import IMG_portfolio_cvmakerwizard_2 from "./../images/portfolio/19_cvmakerwizard/2.png";
import IMG_portfolio_cvmakerwizard_3 from "./../images/portfolio/19_cvmakerwizard/3.png";
import IMG_portfolio_cvmakerwizard_4 from "./../images/portfolio/19_cvmakerwizard/4.png";
import IMG_portfolio_cvmakerwizard_5 from "./../images/portfolio/19_cvmakerwizard/5.png";
import IMG_portfolio_cvmakerwizard_6 from "./../images/portfolio/19_cvmakerwizard/6.png";
import IMG_portfolio_cvmakerwizard_7 from "./../images/portfolio/19_cvmakerwizard/7.png";
import IMG_portfolio_cvmakerwizard_8 from "./../images/portfolio/19_cvmakerwizard/8.png";
import IMG_portfolio_cvmakerwizard_9 from "./../images/portfolio/19_cvmakerwizard/9.png";
import IMG_portfolio_cvmakerwizard_10 from "./../images/portfolio/19_cvmakerwizard/10.png";
import IMG_portfolio_rotatoconcept_coda from "./../images/portfolio/20_RotatoConcept/coda.mp4";
import IMG_portfolio_jacquie_1 from "./../images/portfolio/21_jacquie/1.png";
import IMG_portfolio_jacquie_2 from "./../images/portfolio/21_jacquie/2.png";
import IMG_portfolio_jacquie_3 from "./../images/portfolio/21_jacquie/3.png";
import IMG_portfolio_jacquie_4 from "./../images/portfolio/21_jacquie/4.png";
import IMG_portfolio_minute_1 from "./../images/portfolio/22_Minute/1.png";
import IMG_portfolio_minute_2 from "./../images/portfolio/22_Minute/2.png";
import IMG_portfolio_minute_3 from "./../images/portfolio/22_Minute/3.png";
import IMG_portfolio_realko_1 from "./../images/portfolio/23_Realko/1.png";
import IMG_portfolio_smartlope_1 from "./../images/portfolio/24_SmartLope/1.png";


$(document).ready(function(){
  Lightbox();
  PageProgress();
  FooterAppear();
  BackToTop();
  HideShowHeader();
  mobileMenu();
});

$(function() {
  $('.lazy').lazy({
    effect: "fadeIn"
  });
});
/*--------------------------------------------------
Function Lightbox
---------------------------------------------------*/

function Lightbox() {

	$('#gallery1').magnificPopup({
	    items: [
	      {
	        src: IMG_portfolio_stepicons_1
	      },
				{
					src: IMG_portfolio_stepicons_2
				},
				{
					src: IMG_portfolio_stepicons_3
				},
				{
					src: IMG_portfolio_stepicons_4
				}
	    ],
	    gallery: {
	      enabled: true
	    },
	    type: 'image',
			preloader: true,
			removalDelay: 300,
			mainClass: 'mfp-fade'
	});


  $('#gallery2').magnificPopup({
      items: [
        {
          src: IMG_portfolio_mevaicons_1
        }
      ],
      gallery: {
        enabled: true
      },
      type: 'image',
      preloader: true,
      removalDelay: 300,
      mainClass: 'mfp-fade'
  });



  $('#gallery3').magnificPopup({
      items: [
        {
          src: IMG_portfolio_popsicle_1
        },
        {
          src: IMG_portfolio_popsicle_2
        },
        {
          src: IMG_portfolio_popsicle_3
        },
        {
          src: IMG_portfolio_popsicle_4
        },
        {
          src: IMG_portfolio_popsicle_5
        },
        {
          src: IMG_portfolio_popsicle_6
        },
        {
          src: IMG_portfolio_popsicle_7
        }
      ],
      gallery: {
        enabled: true
      },
      type: 'image',
      preloader: true,
      removalDelay: 300,
      mainClass: 'mfp-fade'
  });



  $('#gallery4').magnificPopup({
      items: [
        {
          src: IMG_portfolio_tinyhouse_1
        },
        {
          src: IMG_portfolio_tinyhouse_2
        },
        {
          src: IMG_portfolio_tinyhouse_3
        },
        {
          src: IMG_portfolio_tinyhouse_4
        },
        {
          src: IMG_portfolio_tinyhouse_5
        },
        {
          src: IMG_portfolio_tinyhouse_6
        },
        {
          src: IMG_portfolio_tinyhouse_7
        },
        {
          src: IMG_portfolio_tinyhouse_8
        },
        {
          src: IMG_portfolio_tinyhouse_9
        },
        {
          src: IMG_portfolio_tinyhouse_10
        },
        {
          src: IMG_portfolio_tinyhouse_11
        },
        {
          src: IMG_portfolio_tinyhouse_12
        }
      ],
      gallery: {
        enabled: true
      },
      type: 'image',
      preloader: true,
      removalDelay: 300,
      mainClass: 'mfp-fade'
  });



  $('#gallery5').magnificPopup({
      items: [
        {
          src: IMG_portfolio_kidsillustrations_1
        },
        {
          src: IMG_portfolio_kidsillustrations_2
        },
        {
          src: IMG_portfolio_kidsillustrations_3
        },
        {
          src: IMG_portfolio_kidsillustrations_4
        },
        {
          src: IMG_portfolio_kidsillustrations_5
        },
        {
          src: IMG_portfolio_kidsillustrations_6
        },
        {
          src: IMG_portfolio_kidsillustrations_7
        }
      ],
      gallery: {
        enabled: true
      },
      type: 'image',
      preloader: true,
      removalDelay: 300,
      mainClass: 'mfp-fade'
  });




  $('#gallery6').magnificPopup({
      items: [
        {
          src: IMG_portfolio_rekrutacjebudowlane_1
        },
        {
          src: IMG_portfolio_rekrutacjebudowlane_2
        },
        {
          src: IMG_portfolio_rekrutacjebudowlane_3
        },
        {
          src: IMG_portfolio_rekrutacjebudowlane_4
        }
      ],
      gallery: {
        enabled: true
      },
      type: 'image',
      preloader: true,
      removalDelay: 300,
      mainClass: 'mfp-fade'
  });




    $('#gallery7').magnificPopup({
        items: [
          {
            src: IMG_portfolio_mobileuikit_1
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });


    $('#gallery8').magnificPopup({
        items: [
          {
            src: IMG_portfolio_mobilefinancial_1
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });


    $('#gallery9').magnificPopup({
        items: [
          {
            src: IMG_portfolio_logospack_1
          },
          {
            src: IMG_portfolio_logospack_2
          },
          {
            src: IMG_portfolio_logospack_3
          },
          {
            src: IMG_portfolio_logospack_4
          },
          {
            src: IMG_portfolio_logospack_5
          },
          {
            src: IMG_portfolio_logospack_6
          },
          {
            src: IMG_portfolio_logospack_7
          },
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery10').magnificPopup({
        items: [
          {
            src: IMG_portfolio_newsletterjacro_1
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery11').magnificPopup({
        items: [
          {
            src: IMG_portfolio_airbike_1
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery12').magnificPopup({
        items: [
          {
            src: IMG_portfolio_platinum_1
          },
          {
            src: IMG_portfolio_platinum_2
          },
          {
            src: IMG_portfolio_platinum_3
          },
          {
            src: IMG_portfolio_platinum_4
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery13').magnificPopup({
        items: [
          {
            src: IMG_portfolio_cvscouter_1
          },
          {
            src: IMG_portfolio_cvscouter_2
          },
          {
            src: IMG_portfolio_cvscouter_3
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery14').magnificPopup({
        items: [
          {
            src: IMG_portfolio_finfront_1
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery15').magnificPopup({
        items: [
          {
            src: IMG_portfolio_fash_1
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery16').magnificPopup({
        items: [
          {
            src: IMG_portfolio_tenko_1
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery17').magnificPopup({
        items: [
          {
            src: IMG_portfolio_cvmaker_1
          },
          {
            src: IMG_portfolio_cvmaker_2
          },
          {
            src: IMG_portfolio_cvmaker_3
          },
          {
            src: IMG_portfolio_cvmaker_4
          },
          {
            src: IMG_portfolio_cvmaker_5
          },
          {
            src: IMG_portfolio_cvmaker_6
          },
          {
            src: IMG_portfolio_cvmaker_7
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery18').magnificPopup({
        items: [
          {
            src: IMG_portfolio_cvmakerdash_1
          },
          {
            src: IMG_portfolio_cvmakerdash_2
          },
          {
            src: IMG_portfolio_cvmakerdash_3
          },
          {
            src: IMG_portfolio_cvmakerdash_4
          },
          {
            src: IMG_portfolio_cvmakerdash_5
          },
          {
            src: IMG_portfolio_cvmakerdash_6
          },
          {
            src: IMG_portfolio_cvmakerdash_7
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });

    $('#gallery19').magnificPopup({
        items: [
          {
            src: IMG_portfolio_cvmakerwizard_1
          },
          {
            src: IMG_portfolio_cvmakerwizard_2
          },
          {
            src: IMG_portfolio_cvmakerwizard_3
          },
          {
            src: IMG_portfolio_cvmakerwizard_4
          },
          {
            src: IMG_portfolio_cvmakerwizard_5
          },
          {
            src: IMG_portfolio_cvmakerwizard_6
          },
          {
            src: IMG_portfolio_cvmakerwizard_7
          },
          {
            src: IMG_portfolio_cvmakerwizard_8
          },
          {
            src: IMG_portfolio_cvmakerwizard_9
          },
          {
            src: IMG_portfolio_cvmakerwizard_10
          }
        ],
        gallery: {
          enabled: true
        },
        type: 'image',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade'
    });


    $('#gallery20').magnificPopup({
        items: [
          {
            src: IMG_portfolio_rotatoconcept_coda
          },
        ],
        gallery: {
          enabled: true
        },
        type: 'iframe',
        preloader: true,
        removalDelay: 300,
        mainClass: 'mfp-fade mfp-movie'
    });



    $('#gallery21').magnificPopup({
      items: [
        {
          src: IMG_portfolio_jacquie_1,
        },
        {
          src: IMG_portfolio_jacquie_2,
        },
        {
          src: IMG_portfolio_jacquie_3,
        },
        {
          src: IMG_portfolio_jacquie_4,
        }
      ],
      gallery: {
        enabled: true
      },
      type: 'image',
      preloader: true,
      removalDelay: 300,
      mainClass: 'mfp-fade'
  });



  $('#gallery22').magnificPopup({
    items: [
      {
        src: IMG_portfolio_minute_1,
      },
      {
        src: IMG_portfolio_minute_2,
      },
      {
        src: IMG_portfolio_minute_3,
      }
    ],
    gallery: {
      enabled: true
    },
    type: 'image',
    preloader: true,
    removalDelay: 300,
    mainClass: 'mfp-fade'
});



$('#gallery23').magnificPopup({
  items: [
    {
      src: IMG_portfolio_realko_1,
    }
  ],
  gallery: {
    enabled: true
  },
  type: 'image',
  preloader: true,
  removalDelay: 300,
  mainClass: 'mfp-fade'
});



$('#gallery24').magnificPopup({
  items: [
    {
      src: IMG_portfolio_smartlope_1,
    }
  ],
  gallery: {
    enabled: true
  },
  type: 'image',
  preloader: true,
  removalDelay: 300,
  mainClass: 'mfp-fade'
});


}//End Lightbox


/*--------------------------------------------------
Function Page Progress
---------------------------------------------------*/

	function PageProgress() {

		var progressPath = document.querySelector('.progress-page path');
		var pathLength = progressPath.getTotalLength();
		progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
		progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
		progressPath.style.strokeDashoffset = pathLength;
		progressPath.getBoundingClientRect();
		progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
		var updateProgress = function () {
			var scroll = $(window).scrollTop();
			var height = $(document).height() - $(window).height();
			var progress = pathLength - (scroll * pathLength / height);
			progressPath.style.strokeDashoffset = progress;
		}
		updateProgress();
		$(window).scroll(updateProgress);

 }//End Page Progress



 /*--------------------------------------------------
Function ScrollTop
---------------------------------------------------*/

	function FooterAppear() {

		if( $('main').length > 0 ){
			$(window).scroll(function() {
				var scroll = $(window).scrollTop();

				if (scroll >= 300) {
					$(".progress-page").addClass('is-active');
				} else {
					$(".progress-page").removeClass('is-active');
				}
			});
		}

		var lastScroll = 0;

		$(window).scroll(function(){
			var scroll = $(window).scrollTop();
			if (scroll > lastScroll) {
				$(".progress-page").addClass("is-visible");
			} else if (scroll < lastScroll) {
				$(".progress-page").removeClass("is-visible");
			}
			lastScroll = scroll;
		});

  }//End FooterAppear

  function BackToTop() {

  $('.scrolltotop').on('click', function() {
    $('html, body').animate({scrollTop : 0},800);
    return false;
  });

}//End BackToTop


/*--------------------------------------------------
Function Hide Show Header
---------------------------------------------------*/

function HideShowHeader() {

  var didScroll;
  var lastScrollTop = 0;
  var delta = 50;
  var navbarHeight = 50;
  var navbarHideAfter = navbarHeight

  $(window).scroll(function(event){
    didScroll = true;
  });

  if( $('.scroll-hide').length > 0 ){

    setInterval(function() {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 100);

  }

  return false;

  function hasScrolled() {
    var st = $(window).scrollTop();

    if(Math.abs(lastScrollTop - st) <= delta)
      return;

    if (st > lastScrollTop && st > navbarHideAfter){
      if( $('.scroll-hide').length > 0 ){
      $('header').addClass('nav-hide');
      }
    } else {
      if( $('.scroll-hide').length > 0 ){
      if(st + $(window).height() < $(document).height()) {
        $('header').removeClass('nav-hide').css("background", "#fff");
      }
      }
    }

    if( st < 120 ){
      $('header').css("background", "none");
    }

    lastScrollTop = st;
  }


}//End Hide Show Header



/*--------------------------------------------------
Function mobileMenu
---------------------------------------------------*/
function mobileMenu(){
  $('#mobile-nav-icon').click(function(){
    $(this).toggleClass('open');
    $('#mobile-nav-content').slideToggle(300);
    $('#page-overlay').fadeToggle(300);

    setTimeout(function(){
      if ($('#mobile-nav-content').is(':visible')) {
        $.scrollLock( true );
      } else{
        $.scrollLock( false );
      }
    }, 350);
  });

  $('#mobile-nav-content a').click(function(){
    $('#mobile-nav-icon').removeClass('open');
    $('#mobile-nav-content').slideUp(300);
    $('#page-overlay').fadeOut(300);

    setTimeout(function(){
      if ($('#mobile-nav-content').is(':visible')) {
        $.scrollLock( true );
      } else{
        $.scrollLock( false );
      }
    }, 350);
  });
}
